<template>
  <potager-modal
    :class="`modal-pedagogy modal-pedagogy--${name}`"
    size="large"
    without-header>
    <template #body>
      <div class="modal-pedagogy__content -mt-10">
        <div
          v-if="!currentStep.component"
          class="modal-pedagogy__header">
          <transition-group name="modal-pedagogy__header--transition">
            <div
              v-if="currentStepIndex === 0"
              :key="0"
              class="leading-0">
              <potager-picture
                :src="`${name}-illustration-card`"
                class="mx-auto modal-pedagogy__illustration"
                native-resolution
                no-lazy-load />
            </div>

            <div
              v-if="currentStepIndex === 1"
              :key="1"
              class="modal-pedagogy__case-study">
              <p class="modal-pedagogy__case-study__title">
                Bernard Tichaut commande pour <b>{{ formatPrice(maxValue - sampleValue, filterPriceOptions) }}</b>
              </p>
              <div class="modal-pedagogy__case-study__row">
                <div class="modal-pedagogy__case-study__col">
                  <p class="modal-pedagogy__case-study__picto">
                    ✅
                  </p>
                  <p>Le solde de sa {{ label }} est <b>suffisant</b></p>
                  <table class="modal-pedagogy__case-study__table">
                    <tbody>
                      <tr>
                        <td>{{ formatPrice(maxValue - sampleValue, filterPriceOptions) }}</td>
                        <td>
                          <component
                            :is="cardIconComponent"
                            :class="`text-${name} mb-1 ml-1`"
                            :style="iconStyle" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-pedagogy__case-study__col">
                  <p class="modal-pedagogy__case-study__picto">
                    ⚠️
                  </p>
                  <p>Le solde de sa {{ label }} est <b>insuffisant</b></p>
                  <table class="modal-pedagogy__case-study__table">
                    <tbody>
                      <tr>
                        <td>{{ formatPrice(sampleValue, filterPriceOptions) }}</td>
                        <td>
                          <component
                            :is="cardIconComponent"
                            :class="`text-${name} mb-1 ml-1`"
                            :style="iconStyle" />
                        </td>
                      </tr>
                      <tr>
                        <td>{{ formatPrice(maxValue - (2 * sampleValue), filterPriceOptions) }}</td>
                        <td>
                          <icon-ptcy-cb class="mb-1 ml-1" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-pedagogy__case-study__col">
                  <p class="modal-pedagogy__case-study__picto">
                    ❌
                  </p>
                  <p>Le solde de sa {{ label }} est <b>vide</b></p>
                  <table class="modal-pedagogy__case-study__table">
                    <tbody>
                      <tr>
                        <td>{{ formatPrice(maxValue - sampleValue, filterPriceOptions) }}</td>
                        <td>
                          <icon-ptcy-cb class="mb-1 ml-1" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              v-if="currentStepIndex === 2"
              :key="2"
              class="modal-pedagogy__case-study">
              <p class="modal-pedagogy__case-study__title">
                Bernard Tichaut commande pour <b>{{ formatPrice(maxValue + sampleValue, filterPriceOptions) }}</b>
              </p>
              <div class="modal-pedagogy__case-study__row">
                <div class="modal-pedagogy__case-study__col">
                  <p class="modal-pedagogy__case-study__picto">
                    ✅
                  </p>
                  <p>Le solde de sa {{ label }} est <b>suffisant</b></p>
                  <table class="modal-pedagogy__case-study__table">
                    <tbody>
                      <tr>
                        <td>{{ formatPrice(maxValue, filterPriceOptions) }}</td>
                        <td>
                          <component
                            :is="cardIconComponent"
                            :class="`text-${name} mb-1 ml-1`"
                            :style="iconStyle" />
                        </td>
                      </tr>
                      <tr>
                        <td>{{ formatPrice(sampleValue, filterPriceOptions) }}</td>
                        <td>
                          <icon-ptcy-cb class="mb-1 ml-1" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="modal-pedagogy__case-study__col">
                  <p class="modal-pedagogy__case-study__picto">
                    ⚠️
                  </p>
                  <p>Le solde de sa {{ label }} est <b>insuffisant</b></p>
                  <table class="modal-pedagogy__case-study__table">
                    <tbody>
                      <tr>
                        <td>{{ formatPrice(sampleValue, filterPriceOptions) }}</td>
                        <td>
                          <component
                            :is="cardIconComponent"
                            :class="`text-${name} mb-1 ml-1`"
                            :style="iconStyle" />
                        </td>
                      </tr>
                      <tr>
                        <td>{{ formatPrice(maxValue, filterPriceOptions) }}</td>
                        <td>
                          <icon-ptcy-cb class="mb-1 ml-1" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <table class="modal-pedagogy__case-study__table">
                  <tbody>
                    <tr>
                      <td>{{ formatPrice(maxValue + sampleValue, filterPriceOptions) }}</td>
                      <td>
                        <icon-ptcy-cb class="mb-1 ml-1" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-if="currentStepIndex === 3"
              :key="3"
              class="modal-pedagogy__case-study">
              <p class="modal-pedagogy__case-study__title">
                Bernard Tichaut se fait livrer le mercredi
              </p>
              <potager-picture
                class="w-80 bp768:w-full mx-auto mt-12 bp768:mt-0 mb-2"
                src="modal-pedagogy-days" />

              <div class="flex justify-center">
                <component
                  :is="cardIconFullComponent"
                  :style="{
                    color: cardIconColor,
                  }"
                  class="mr-2" />

                <icon-ptcy-cb class="mr-[20%]" />
              </div>
            </div>

            <div
              v-if="currentStep.component"
              :key="currentStepIndex"
              class="flex justify-center items-center h-full">
              <p
                :class="`text-lg bp768:text-base bp768:leading-tight text-${name}-alt font-extrabold`"
                v-html="currentStep.title" />
            </div>
          </transition-group>
        </div>

        <div class="modal-pedagogy__body pt-4">
          <transition-group name="modal-pedagogy__body--transition">
            <template v-for="(step, index) in steps">
              <div
                v-if="currentStepIndex === index && !currentStep.component"
                :key="index">
                <p
                  :class="`text-lg bp768:text-base bp768:leading-tight text-${name}-alt font-extrabold mb-6 bp768:mb-4`"
                  v-html="step.title" />

                <p
                  class="bp768:text-xs.5"
                  v-html="step.text" />
              </div>

              <div
                v-else-if="currentStepIndex === index && currentStep.component"
                :key="`component-${index}`">
                <p
                  :class="`text-lg bp768:text-base bp768:leading-tight text-${name}-alt font-extrabold mb-6 bp768:mb-4`"
                  v-html="step.title" />

                <p
                  class="bp768:text-xs.5"
                  v-html="step.text" />

                <component
                  :is="currentStep.component"
                  :key="index"
                  ref="currentStepComponent"
                  v-bind="currentStep.componentProps"
                  @canSubmit="canGoNext = $event"
                  @success="close" />
              </div>
            </template>
          </transition-group>
        </div>

        <component
          :is="getBreadcrumbComponent"
          v-if="!$mq.bp768"
          :card-icon-color="cardIconColor"
          :color="colors?.primary"
          :current-step-index="currentStepIndex"
          :steps="steps"
          class="max-w-56 mx-auto mt-8" />
      </div>
    </template>

    <template #footer>
      <div class="flex justify-center gap-4 items-center w-full">
        <component
          :is="getBreadcrumbComponent"
          v-if="$mq.bp768"
          :current-step-index="currentStepIndex"
          :steps="steps"
          class="absolute -inset-x-0.5 -top-px -mt-px" />

        <potager-button
          :disabled="currentStepIndex === 0"
          class="min-w-40 bp768:min-w-0 bp768:w-full"
          theme="stroke"
          @onClick="prevStep">
          Précédent
        </potager-button>

        <potager-button
          :is-disabled="!canGoNext"
          :is-loading="isLoading"
          class="modal-pedagogy__custom-bg min-w-40 bp768:min-w-0 bp768:w-full"
          @onClick="nextStep">
          {{
            hasAnotherStep ? 'Continuer' : [PaymentMethodIntegration.Swile,
                                            PaymentMethodIntegration.Edenred].includes(paymentMethod.integration) ?
                                              'Connexion' : 'Ajouter ma carte'
          }}
        </potager-button>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatPrice } from 'PotagerLogic/Formatting';

import { getPaymentMethodByName, PaymentMethods, PaymentMethodsArray } from 'Classes/payment-methods';
import { PaymentMethodIntegration, PaymentMethodType } from 'PotagerLogic/Enums/PaymentMethods';
import { capitalize } from 'PotagerLogic/Formatting';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import IconPtcyCb from 'Icons/color/IconPtcyCb';

import paygreenNewCardForm from 'Components/paygreen/PaygreenNewCardForm';

import { PAYGREEN_PROCESSING_LOADER } from 'Mixins/payments/PaygreenMixin';

export default {

  props: {
    name: {
      type: String,
      required: true,
      validator: (value) => PaymentMethodsArray
        .map((pm) => pm.name)
        .includes(value),
    },
    cardIconComponent: {
      type: Object,
      required: true,
    },
    cardIconFullComponent: {
      type: Object,
      required: true,
    },
    cardIconColor: {
      type: String,
      default: undefined,
    },
    cardIconBgColor: {
      type: String,
      default: undefined,
    },
    colors: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    PotagerModal,
    PotagerButton,
    PotagerPicture,
    IconPtcyCb,
  },

  data: () => ({
    currentStepIndex: 0,
    sampleValue: 5,
    filterPriceOptions: {
      freeString: 'Offert',
    },
    canGoNext: true,
    PaymentMethodIntegration,
  }),

  computed: {
    ...mapGetters('app', ['getLuncheons']),
    isLoading() {
      return this.isRedirectLoading || (this.currentStep.id === 'paygreenForm' && this.$wait.is(PAYGREEN_PROCESSING_LOADER));
    },
    maxValue() {
      return this.getLuncheons?.dailyMaxLimit;
    },
    iconStyle() {
      return {
        color: this.cardIconColor,
        background: this.cardIconBgColor,
        borderRadius: '4px',
      };
    },
    steps() {
      const maxValue = formatPrice(this.maxValue, this.filterPriceOptions);
      const textPrefix = this.name === PaymentMethods.Pluxee.name
        ? `<b>Les anciennes cartes Sodexo labelisées Conecs sont également acceptées.</b> `
        : '';
      let steps = [
        {
          title: `Paiement par ${this.label}`,
          text: `${textPrefix}Le paiement est effectué automatiquement la veille du jour de vos livraisons. Votre ${this.label} sera utilisée pour le paiement de vos commandes dans la limite du plafond journalier réglementaire de ${maxValue}.`,
        },
        {
          title: `Votre commande est inférieure ou égale à ${maxValue}&nbsp;?`,
          text: `Si le solde de votre ${this.label} est suffisant, l’intégralité de la commande y sera débitée.  S’il est insuffisant, nous débiterons le restant sur votre carte bancaire. S’il est épuisé, tout sera débité sur votre carte bancaire.`,
        },
        {
          title: `Votre commande est supérieure à ${maxValue}&nbsp;?`,
          text: `Si le solde de votre ${this.label} est suffisant, l’intégralité de la commande y sera débitée.  S’il est insuffisant, nous débiterons le restant sur votre carte bancaire. S’il est épuisé, tout sera débité sur votre carte bancaire.`,
        },
        {
          title: 'On récapitule',
          text: `Vos commandes seront payées et débitées automatiquement sur votre ${this.label} en fonction du solde disponible à l’heure du prélèvement, c’est à dire entre 00h30 et 2h la veille de votre livraison. Votre carte bancaire est essentielle pour compléter le montant de vos commandes si besoin.`,
        },
      ];

      const paygreenFormStep = {
        id: 'paygreenForm',
        title: `Ajouter ma ${this.label}`,
        component: paygreenNewCardForm,
        componentProps: {
          hideSubmit: true,
          name: this.name,
          context: 'modal',
        },
      };

      // si on est dans le tunnel
      if (this.innerForm) {
        steps = [
          ...steps,
          paygreenFormStep,
        ];
      }

      return steps;
    },
    isRedirectLoading() {
      // this.paymentMethod.loaders is a object
      // we check if one of the attribut has a this.$wait.is state
      return this.paymentMethod.loaders && Object.values(this.paymentMethod.loaders)
        .some((loader) => this.$wait.is(loader));
    },
    hasAnotherStep() {
      return this.currentStepIndex < this.steps.length - 1;
    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    getBreadcrumbComponent() {
      return {
        template: `
          <ul class="flex">
            <li
              class="w-3/12 px-0.5"
              v-for="(step, index) in steps"
              :key="index">
              <span
                :style="{
                'background-color': currentStepIndex >= index ? color : '#E5E5E5',
                'color': currentStepIndex >= index ? '#FFFFFF' : '#000000',
              }"
                :class="[
                  {
                    'block h-0.5 modal-pedagogy__custom-bg rounded': true,
                    'opacity-100': currentStepIndex >= index,
                    }
                ]"
              />
            </li>
          </ul>
        `,
        props: {
          steps: {
            type: Array,
            required: true,
          },
          currentStepIndex: {
            type: Number,
            required: true,
          },
          cardIconColor: {
            type: String,
            default: '#000000',
          },
          color: {
            type: String,
            default: '#000000',
          },
        },
      };
    },
    tracking() {
      return {
        name: `Modal${capitalize(this.name)}`,
        params: {
          step: this.currentStepIndex,
        },
      };
    },
    paymentMethod() {
      return getPaymentMethodByName(this.name);
    },
    innerForm() {
      const isInTunnel = this.$route.name.includes('tunnel');
      return isInTunnel && this.paymentMethod?.type === PaymentMethodType.RestaurantCard && ![PaymentMethodIntegration.Swile, PaymentMethodIntegration.Edenred].includes(this.paymentMethod?.integration);
    },
    label() {
      return this.paymentMethod?.label;
    },
  },

  methods: {
    formatPrice,
    prevStep() {
      this.canGoNext = true;
      if (this.currentStepIndex > 0) this.currentStepIndex -= 1;
    },
    nextStep() {
      if (this.hasAnotherStep) {
        this.currentStepIndex += 1;
      } else {
        this.lastAction();
      }
    },
    close() {
      this.$modal.close();
    },
    lastAction() {
      if (this.innerForm) {
        window.paygreenjs.submitPayment();
        return;
      }

      const { backRoute } = this.$route.query;
      const redirectRoute = backRoute || this.$potagerRoute?.name || this.$route.name;

      this.paymentMethod.actions.redirect(redirectRoute);
      this.$modal.close();
    },
    swipe(e) {
      this[e.type === 'swipeleft' ? 'nextStep' : 'prevStep']();
    },
  },

  mounted() {
    document.addEventListener('swiperight', this.swipe);
    document.addEventListener('swipeleft', this.swipe);
  },

  beforeUnmount() {
    document.removeEventListener('swiperight', this.swipe);
    document.removeEventListener('swipeleft', this.swipe);
  },

};
</script>

<style lang="scss" scoped>
.modal-pedagogy {
  $this: &;

  &--edenred {
    #{$this} {
      &__header {
        background: $edenredAlt;
      }

      &__illustration {
        @apply w-84 bp768:w-full;
      }

      &__custom-bg {
        background: $edenred;

        @include hover() {
          &:not(:disabled) {
            background: color-adjust($edenred, $lightness: $hover-darken);
          }
        }
      }
    }
  }

  &--swile {
    #{$this} {
      &__header {
        background: $swileAlt;
        color: $swileAlt2;
      }

      &__illustration {
        @apply w-124;
      }

      &__custom-bg {
        background: $swile;

        @include hover() {
          &:not(:disabled) {
            background: color-adjust($swile, $lightness: $hover-darken);
          }
        }
      }
    }
  }

  &--up {
    #{$this} {
      &__header {
        background: $upAlt;
      }

      &__illustration {
        @apply w-84 bp768:w-full relative -bottom-4;
      }

      &__custom-bg {
        background: $up;

        @include hover() {
          &:not(:disabled) {
            background: color-adjust($up, $lightness: $hover-darken);
          }
        }
      }
    }
  }

  &--bimpli {
    #{$this} {
      &__header {
        background: $bimpliAlt;
      }

      &__illustration {
        @apply w-84 bp768:w-full relative -bottom-4;
      }

      &__custom-bg {
        background: $bimpli;

        @include hover() {
          &:not(:disabled) {
            background: color-adjust($bimpli, $lightness: $hover-darken);
          }
        }
      }
    }
  }

  &--pluxee {
    #{$this} {
      &__header {
        background: $pluxeeAlt;
      }

      &__illustration {
        @apply w-84 bp768:w-full relative -bottom-4;
      }

      &__custom-bg {
        background: $pluxee;

        @include hover() {
          &:not(:disabled) {
            background: color-adjust($pluxee, $lightness: $hover-darken);
          }
        }
      }
    }
  }

  :deep().modal-container__content {
    max-width: 50rem;
  }

  @include bp768() {
    :deep().modal-container__body {
      padding-bottom: 0;
    }
  }

  &__header {
    min-height: 15.625rem;
    position: relative;
    margin: -2rem -2rem 2rem -2rem;
    color: $white;

    @include bp768() {
      height: auto;
      min-height: 12rem;
      margin: -1.875rem -1.875rem 1.5rem -1.875rem;
      border-radius: 0;
    }

    &--small {
      height: 5.625rem;
    }
  }

  &__body {
    position: relative;
    min-height: 8.25rem;

    @include bp768() {
      min-height: 0;
    }
  }

  &__case-study {
    padding: 2rem;
    height: 100%;

    @include bp768() {
      padding: 1.5rem;
    }

    &__title {
      font-weight: $weightBold;
      @apply text-base;
      margin-bottom: 1.5rem;
    }

    &__row {
      display: flex;

      @include bp768() {
        flex-direction: column;
        margin-bottom: -1rem;
      }
    }

    &__col {
      width: 33.333%;
      border-right: solid 1px $white;
      padding: 0 1rem;

      &:last-child {
        border-right: 0;
      }

      @include bp768() {
        width: 100%;
        border-right: 0;
        display: flex;
        align-items: center;
        padding: 0 0 1rem 0;
        margin-bottom: 1rem;
        border-bottom: solid 1px $white;
        @apply text-xs.5;
        line-height: $baseLh;
        text-align: left;
        min-height: 3.75rem;

        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    &__picto {
      margin-bottom: .5rem;

      @include bp768() {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }

    &__table {
      @apply text-lg;
      font-weight: $weightExtrabold;
      margin: 1rem auto 0 auto;

      @include bp768() {
        margin: 0;
        margin-left: 1rem;
        @apply text-sm.5;
        line-height: 0;
      }

      td {
        &:first-child {
          text-align: right;
        }

        &:last-child {
          text-align: left;
        }
      }
    }
  }
}

@include define-transition(both, modal-pedagogy__header--transition, modal-pedagogy__body--transition) {
  transition: transform .5s ease, opacity .5s ease;
}

@include define-transition(leave, modal-pedagogy__header--transition, modal-pedagogy__body--transition) {
  position: absolute;
  width: 100%;
}

@include define-transition(both, modal-pedagogy__header--transition, modal-pedagogy__body--transition) {
  opacity: 0;
}

@include define-transition(enter, modal-pedagogy__body--transition) {
  transform: translate(1rem, 0);
}

@include define-transition(leave, modal-pedagogy__body--transition) {
  transform: translate(-1rem, 0);
}
</style>
