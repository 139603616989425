import { closeModal as jenCloseModal, config, openModal as jenOpenModal, } from 'jenesius-vue-modal';

let beforeCloseMethod = null;

export const openModal = async (component, componentProps, beforeClose, namespace) => {
  beforeCloseMethod = beforeClose;
  const modal = await jenOpenModal(component, componentProps, { namespace });
  modal.onclose = (event) => {
    if(componentProps?.closable === false && (event.esc || event.background)) return false;
  }
};

export const closeModal = (params, namespace) => {
  const close = () => {
    jenCloseModal({ namespace })
      // avoid error when multiple close calls
      .catch(() => {
      })
      .finally(() => beforeCloseMethod = null);
  };

  if (beforeCloseMethod) {
    const result = beforeCloseMethod({ params });
    if (result && typeof result.then === 'function') result.then(close);
    else close();
  } else {
    close();
  }
};

export default {
  install(app) {
    config({
      store: app.config.globalProperties.$store,
      router: app.config.globalProperties.$router,
      animation: 'fade-popin',
    });

    app.config.globalProperties.$modal = {
      open: openModal,
      close: closeModal
    };
  }
};
